export default {
  name: 'PScrollableContainer',
  props: {
    /**
     * @type {import('vue').PropType<import('./variables').Theme>}
     */
    theme: {
      type: String,
      default: 'default'
    },
    width: {
      type: String,
      default: ''
    },
    trackWidth: {
      type: String,
      default: ''
    },
    /**
     * Ширина трекбара равна ширине скроллбара
     */
    compactMode: {
      type: Boolean,
      default: false
    },
    rightOffsetCompensation: {
      type: Boolean,
      default: false
    },
    centeredThumb: {
      type: Boolean,
      default: true
    },
    onlyIfNeeded: {
      type: Boolean,
      default: false
    },
    fillAvailableSpace: {
      type: Boolean,
      default: true
    },
    fadeTop: {
      type: Boolean,
      default: false
    },
    fadeBottom: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes: function classes() {
      return ["p-scrollable-container--".concat(this.theme), {
        'p-scrollable-container--right-offset-compensation': this.rightOffsetCompensation,
        'p-scrollable-container--centered-thumb': this.centeredThumb,
        'p-scrollable-container--only-if-needed': this.onlyIfNeeded,
        'p-scrollable-container--fill-available-space': this.fillAvailableSpace,
        'p-scrollable-container--fade-top': this.fadeTop,
        'p-scrollable-container--fade-bottom': this.fadeBottom
      }];
    },
    styles: function styles() {
      var styles = {};
      if (this.trackWidth) {
        styles['--scrollbar-track-width'] = this.trackWidth;
      } else if (this.compactMode) {
        styles['--scrollbar-track-width'] = 'var(--scrollbar-width)';
      }
      if (this.width) {
        styles['--scrollbar-width'] = this.width;
      }
      return styles;
    }
  }
};