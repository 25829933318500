export var useUserRightsStore = definePiniaStore('userrights-store', function () {
  var isSuperUser = ref(false);
  var isNoMeetingsAccess = ref(false);
  var isNoMeetingsHistory = ref(false);
  var isManagerCC = ref(false);
  function setIsSuperUser(payload) {
    isSuperUser.value = payload;
  }
  function setIsNoMeetingsAccess(payload) {
    isNoMeetingsAccess.value = payload;
  }
  function setIsNoMeetingsHistory(payload) {
    isNoMeetingsHistory.value = payload;
  }
  function setIsManagerCC(payload) {
    isManagerCC.value = payload;
  }
  return {
    isSuperUser: isSuperUser,
    isNoMeetingsAccess: isNoMeetingsAccess,
    isNoMeetingsHistory: isNoMeetingsHistory,
    isManagerCC: isManagerCC,
    setIsSuperUser: setIsSuperUser,
    setIsNoMeetingsAccess: setIsNoMeetingsAccess,
    setIsNoMeetingsHistory: setIsNoMeetingsHistory,
    setIsManagerCC: setIsManagerCC
  };
});