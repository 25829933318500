import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { lockBody, unlockBody } from '@@/src/shared/lib/utils/commonUtils';
export default {
  data: function data() {
    return {
      component: null,
      options: null,
      newComponent: null,
      newOptions: null,
      isOverlayVisible: false,
      isContentVisible: false,
      isOverflowing: false
    };
  },
  computed: {
    classes: function classes() {
      return [this.options && this.options.className ? this.options.className : '', this.options && this.options.modClass ? this.options.modClass : '', _defineProperty({}, this.$style._overflow, this.isOverflowing)];
    }
  },
  watch: {
    $route: function $route() {
      this.onClose();
    }
  },
  beforeMount: function beforeMount() {
    this.$modal.event.$on('open', this.onOpen);
    this.$modal.event.$on('close', this.onClose);
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy: function beforeDestroy() {
    this.$modal.event.$off('open', this.onOpen);
    this.$modal.event.$off('close', this.onClose);
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    onOpen: function onOpen(component, options) {
      if (this.isOverlayVisible) {
        this.newComponent = component;
        this.newOptions = options || null;
        this.isContentVisible = false;
        // console.warn('[TheModal] Модальное окно уже открыто');
      } else {
        lockBody();
        this.isOverlayVisible = true;
        this.component = component;
        if (options) {
          this.options = options;
        }
      }
    },
    onClose: function onClose() {
      this.isContentVisible = false;
    },
    handleKeydown: function handleKeydown(e) {
      if (this.isOverlayVisible && e.key === 'Escape') {
        this.onClose();
      }
    },
    afterContentClose: function afterContentClose() {
      var _this = this;
      this.isOverflowing = false;
      if (this.newComponent) {
        this.component = this.newComponent;
        this.options = this.newOptions;
        this.newComponent = null;
        this.newOptions = null;
        this.$nextTick(function () {
          _this.isContentVisible = true;
        });
      } else {
        this.component = null;
        this.options = null;
        this.isOverlayVisible = false;
      }
    },
    afterOverlayClose: function afterOverlayClose() {
      unlockBody();
    }
  }
};