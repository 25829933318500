var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('SModal', {
    staticClass: "offer-modal",
    attrs: {
      "modal-key": "send-offer-modal",
      "theme": "white-blue-center",
      "type": "center-popup",
      "type-options": _setup.defaultTypeOptions
    },
    on: {
      "close": _setup.closeModal
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('p', {
          staticClass: "text-business-l offer-modal__title"
        }, [_vm._v("\n      " + _vm._s(_vm.options.title) + "\n    ")])];
      },
      proxy: true
    }, {
      key: "bottom",
      fn: function fn() {
        var _vm$options$buttons;
        return [_c('div', {
          staticClass: "offer-modal__action-button"
        }, [_c('div', {
          staticClass: "offer-modal__action-buttons"
        }, [((_vm$options$buttons = _vm.options.buttons) === null || _vm$options$buttons === void 0 ? void 0 : _vm$options$buttons.length) === 1 ? _c('SButton', {
          staticClass: "offer-modal__action-button",
          attrs: {
            "theme": "secondary"
          },
          on: {
            "click": _setup.closeModal
          }
        }, [_vm._v("\n          Закрыть\n        ")]) : _vm._l(_vm.options.buttons, function (button) {
          return _c('SButton', _vm._b({
            key: button.text,
            staticClass: "offer-modal__action-button",
            attrs: {
              "loading": button.action ? _setup.modalsStore.sendOfferIsLoading : false,
              "disabled": button.action && _vm.options.input && _setup.isPhoneDisabled
            },
            on: {
              "click": function click($event) {
                return _setup.handleButtonClick(button);
              }
            }
          }, 'SButton', button, false), [_vm._v("\n            " + _vm._s(button.text) + "\n          ")]);
        })], 2)])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('p', {
    staticClass: "offer-modal__content"
  }, [_vm.options.input ? _c('SInput', {
    staticClass: "offer-modal__input",
    attrs: {
      "placeholder": "Номер телефона клиента",
      "mask": "phone",
      "type": "tel"
    },
    model: {
      value: _setup.modalsStore.sendOfferClientPhone,
      callback: function callback($$v) {
        _vm.$set(_setup.modalsStore, "sendOfferClientPhone", $$v);
      },
      expression: "modalsStore.sendOfferClientPhone"
    }
  }) : [_vm._v(_vm._s(_vm.options.content))]], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };