export var useClientStore = definePiniaStore('client-store', function () {
  var client = ref(null);
  function setClient(payload) {
    client.value = payload;
  }
  ;
  var clientId = computed(function () {
    var _client$value;
    return (_client$value = client.value) === null || _client$value === void 0 ? void 0 : _client$value.id;
  });
  var resetClient = function resetClient() {
    client.value = null;
  };
  return {
    client: client,
    setClient: setClient,
    clientId: clientId,
    resetClient: resetClient
  };
});