import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.number.constructor.js";
import PInputInterval from './PInputInterval';
export default {
  name: 'PInputIntervalFilterField',
  components: {
    PInputInterval: PInputInterval
  },
  props: {
    /**
     * Если передано, используется для события `input:keyed`
     * Название поля левого значения.
     */
    nameMin: {
      type: String,
      default: ''
    },
    /**
     * Если передано, используется для события `input:keyed`
     * Название поля правого значения.
     */
    nameMax: {
      type: String,
      default: ''
    },
    /**
     * Заголовок поля
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Спеки: граничные значения для ползунка
     */
    spec: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          min: 1,
          max: 100
        };
      }
    },
    /**
     * Фасеты: диапазон перемещения ползунка
     */
    facet: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    valueMin: {
      type: [String, Number],
      default: ''
    },
    valueMax: {
      type: [String, Number],
      default: ''
    },
    positiveOnly: {
      type: Boolean,
      default: true
    },
    step: {
      type: Number,
      default: 1
    },
    theme: {
      type: String,
      default: 'panel-white'
    },
    /**
     * Свойства, которые будут напрямую переданы в `<p-input-interval />`
     */
    inputIntervalProps: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      noTransition: false,
      value: [this.valueMin || this.spec.min, this.valueMax || this.spec.max]
    };
  },
  computed: {
    computedInputIntervalProps: function computedInputIntervalProps() {
      return _objectSpread(_objectSpread({}, this.inputIntervalProps), {}, {
        label: this.label,
        value: this.value,
        min: this.spec.min,
        max: this.spec.max,
        step: this.step,
        theme: this.theme
      });
    }
  },
  watch: {
    facet: function facet() {
      if (!this.valueMin && !this.valueMax) {
        this.applyFacet();
      }
    },
    spec: function spec() {
      if (!this.valueMin && !this.valueMax) {
        this.value = [this.spec.min, this.spec.max];
      }
    },
    valueMin: function valueMin(newValue) {
      this.value = [this.getActualChangedValue(newValue, this.value[0], this.spec.min), this.value[1]];
    },
    valueMax: function valueMax(newValue) {
      this.value = [this.value[0], this.getActualChangedValue(newValue, this.value[1], this.spec.max)];
    }
  },
  created: function created() {
    this.applyFacet();
  },
  methods: {
    applyFacet: function applyFacet() {
      var _this$facet, _this$facet2;
      if ((_this$facet = this.facet) !== null && _this$facet !== void 0 && _this$facet.min && (_this$facet2 = this.facet) !== null && _this$facet2 !== void 0 && _this$facet2.max && !this.valueMin && !this.valueMax) {
        this.value = [this.facet.min, this.facet.max];
      } else {
        console.warn('Something wrong with range facets');
      }
    },
    getActualChangedValue: function getActualChangedValue(newValue, prevValue, specValue) {
      if (newValue === prevValue) {
        return prevValue;
      }
      var isEmptyValue = newValue === '';
      if (isEmptyValue && prevValue === specValue) {
        return prevValue;
      }
      return isEmptyValue ? specValue : newValue;
    },
    handleInput: function handleInput(value) {
      this.value = value;
      this.$emit('input', value);
      if (this.nameMin && this.nameMax) {
        this.$emit('input:keyed', _defineProperty(_defineProperty({}, this.nameMin, this.value[0]), this.nameMax, this.value[1]));
      }
    }
  }
};