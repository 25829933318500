import _typeof from "@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
var $routes = {
  auth: {
    base: '/api/panel/auth/',
    login: '/api/panel/auth/token/login/',
    logout: '/api/panel/auth/logout/',
    refresh: '/api/panel/auth/token/refresh/'
  },
  project: {
    current: '/api/panel/project/'
  },
  personalData: {
    simple: '/api/panel/personal_data/simple/',
    terms: '/api/panel/personal_data/terms_of_use/'
  },
  meetings: {
    base: '/api/panel/meeting/',
    list: function list() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return "/api/panel/meeting/".concat(query ? "?".concat(_typeof(query) === 'object' ? new URLSearchParams(query) : query) : '');
    },
    specs: '/api/panel/meeting/specs/',
    meetingSpecs: function meetingSpecs(id) {
      return "/api/panel/meeting/specs/?meeting_id=".concat(id);
    },
    missedList: '/api/panel/meeting/missed',
    upcomingList: '/api/panel/meeting/upcoming/',
    getStatistic: '/api/panel/meeting/get_statistic/',
    meeting: function meeting(id) {
      return "/api/panel/meeting/".concat(id, "/");
    },
    start: function start(id) {
      return "/api/panel/meeting/".concat(id, "/start/");
    },
    current: '/api/panel/meeting/current/',
    finish: function finish(id) {
      return "/api/panel/meeting/".concat(id, "/finish/");
    }
  },
  about: {
    categories: function categories(projectSlug) {
      return "/api/panel/about/".concat(projectSlug, "/");
    },
    sliders: function sliders(projectSlug, categorySlug) {
      return "/api/panel/about/".concat(projectSlug, "/").concat(categorySlug, "/");
    }
  },
  flats: {
    layout: function layout(id) {
      return "/api/panel/flats/?layout=".concat(id);
    },
    facets: '/api/panel/flats/facets/'
  },
  favorites: {
    base: function base(id) {
      return "/api/panel/meeting/".concat(id, "/favorite_add/");
    },
    amoBase: function amoBase(id) {
      return "/api/panel/meeting/".concat(id, "/favorite_add_amo/");
    },
    delete: function _delete(id) {
      return "/api/panel/meeting/".concat(id, "/favorite_delete/");
    },
    deleteAmo: function deleteAmo(id) {
      return "/api/panel/meeting/".concat(id, "/favorite_delete_amo/");
    },
    deleteAll: function deleteAll(id) {
      return "/api/panel/meeting/".concat(id, "/clear_all_favorites/");
    },
    offerCollection: '/api/panel/offer_collection/'
  },
  mortgage: {
    specs: '/api/panel/offer/specs/'
  },
  statistic: {
    base: '/api/panel/statistic/',
    addPresentation: '/api/panel/statistic/add_presentation_statistic/'
  },
  cabinets: {
    favorites: '/api/v2/users/managers/interest'
  }
};
export default (function (ctx, inject) {
  // Inject routes to the context as $routes
  ctx.$routes = $routes;
  inject('routes', $routes);
});
export var useRoutes = function useRoutes() {
  return $routes;
};