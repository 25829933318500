import { ALL_PAGES_ACCESS_USER_ROLES, MANAGER_CC_USER_ROLES } from '@@/src/config/auth';

const isMeetingInProgress = ({ getters }) => getters['meeting/isMeetingInProgress'];
const isMeetingNotInProgress = ({ getters }) => !getters['meeting/isMeetingInProgress'];

/**
 * activeCheckAlias: по умолчанию ссылка для проверки активности используется ссылка (to),
 *                   но возможно установить дополнительный флаг.
 * disabledFor: массив пользовательских ролей, для которых пункт меню будет недоступен (для остальных доступен).
 * enabledFor: массив пользовательских ролей, для которых пункт меню будет доступен (для остальных недоступен).
 * enabledIf: коллбэк, определяющий состояние, при котором пункт меню будет доступен.
 *
 * @type {[object]}
 */
export const MAIN_NAVIGATION = [
  {
    icon: 'house',
    to: $navigation => $navigation.routeWithDefaultParams('project-slug-meeting-index'),
    activeCheckAlias: 'project-slug-meeting-index',
    block: 'Встречи',
    enabledIf: isMeetingNotInProgress,
    disabledFor: MANAGER_CC_USER_ROLES,
  },
  {
    icon: 'info',
    to: $navigation => $navigation.routeWithDefaultParams('project-slug-company'),
    block: 'О компании',
    enabledFor: ALL_PAGES_ACCESS_USER_ROLES,
    enabledIf: isMeetingInProgress,
  },
  {
    icon: 'pin',
    to: $navigation => $navigation.routeWithDefaultParams('project-slug-location'),
    block: 'Инфраструктура и расположение',
    enabledFor: ALL_PAGES_ACCESS_USER_ROLES,
    enabledIf: isMeetingInProgress,
  },
  {
    icon: 'camera',
    to: $navigation => $navigation.routeWithDefaultParams('project-slug-about'),
    block: 'О проекте',
    enabledFor: ALL_PAGES_ACCESS_USER_ROLES,
    enabledIf: isMeetingInProgress,
  },
  {
    icon: 'building',
    to($navigation) {
      const currentRouteName = $navigation.route.name;
      if (this.baseRoutes.includes(currentRouteName)) {
        return $navigation.routeWithDefaultParams(currentRouteName);
      }

      const { from } = $navigation.route.query;
      const routeName = getFlatsBackNavigation(from);

      return $navigation.routeWithDefaultParams(routeName);
    },
    baseRoutes: ['project-slug-flats-chessboard', 'project-slug-flats', 'project-slug-flats-visual'],
    activeCheckAlias: ['project-slug-flats-chessboard', 'project-slug-flats', 'project-slug-flats-visual', 'project-slug-flats-id'],
    block: 'Подбор',
    enabledFor: ALL_PAGES_ACCESS_USER_ROLES,
    enabledIf: isMeetingInProgress,
  },
  {
    icon: 'bank',
    to: $navigation => $navigation.routeWithDefaultParams('project-slug-purchase-mortgage'),
    block: 'Ипотека',
    enabledFor: ALL_PAGES_ACCESS_USER_ROLES,
    enabledIf: isMeetingInProgress,
  },
  {
    icon: 'statistics',
    to: $navigation => $navigation.routeWithDefaultParams('project-slug-progress'),
    block: 'Ход строительства',
    enabledFor: ALL_PAGES_ACCESS_USER_ROLES,
    enabledIf: isMeetingInProgress,
  },
  {
    icon: 'heart',
    to: $navigation => $navigation.routeWithDefaultParams('project-slug-favorites'),
    block: 'Избранное',
  },
  {
    icon: 'check',
    to: $navigation => $navigation.routeWithDefaultParams('project-slug-meeting-finish'),
    block: 'Анкета',
    enabledIf: isMeetingInProgress,
    disabledFor: MANAGER_CC_USER_ROLES,
  },
];

export function getFlatsBackNavigation(from) {
  const aliasToRouteName = {
    chessboard: 'project-slug-flats-chessboard',
    visual: 'project-slug-flats-visual',
    selection: 'project-slug-flats',
  };

  return aliasToRouteName[from] || aliasToRouteName.chessboard;
}
