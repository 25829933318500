import { useAboutPageStore,
  useFavoritesStore,
  useFlatsStore,
  useSurveyStore } from '@@/src/shared/@pinia';

export const actions = {
  resetSessionState() {
    useSurveyStore().clearSurveyMeeting();
    useFavoritesStore().clearFavorites();
    useAboutPageStore()?.reset();
    useFlatsStore().reset();
  },
};
