var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('SModal', {
    staticClass: "discount-modal",
    attrs: {
      "modal-key": "config-discount-modal",
      "theme": "white-blue-center",
      "type": "center-popup",
      "type-options": _setup.defaultTypeOptions
    },
    on: {
      "close": _setup.closeModal
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('p', {
          staticClass: "text-business-l discount-modal__title"
        }, [_vm._v("\n      Выберите квартиру и скидку\n    ")])];
      },
      proxy: true
    }, {
      key: "bottom",
      fn: function fn() {
        return [_c('SButton', {
          staticClass: "discount-modal__action-button",
          attrs: {
            "disabled": _setup.isSomeDiscountPriceError || _setup.isSomeDiscountDateError || !_setup.lots.length,
            "loading": _setup.isDiscountsApplying
          },
          on: {
            "click": _setup.handleApplyDiscount
          }
        }, [_vm._v("\n      Применить скидку\n    ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "discount-modal__caption p2"
  }, [_c('div', [_vm._v("Квартира")]), _vm._v(" "), _c('div', [_vm._v("Скидка")]), _vm._v(" "), _c('div', [_vm._v("Дата окончания")])]), _vm._v(" "), _c('div', {
    staticClass: "scrollable-container"
  }, _vm._l(_setup.lots, function (lot, index) {
    var _lot$discount;
    return _c('div', {
      key: index,
      staticClass: "discount-modal__content"
    }, [_c('div', {
      staticClass: "card"
    }, [_c('div', {
      staticClass: "card__details"
    }, [_c('div', {
      staticClass: "card__image"
    }, [_c('SImageLazy', {
      attrs: {
        "src": _setup.getPlanImg(lot)
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "card__info"
    }, [_c('p', [_c('span', {
      staticClass: "p4 fw-500 card__discounted-price"
    }, [_vm._v(_vm._s(_setup.splitThousands(lot.originalPrice)) + " ₽")]), _vm._v(" "), _c('span', {
      staticClass: "p4 card__original-price"
    }, [_vm._v(_vm._s(_setup.splitThousands(lot.fullFinalPrice)) + " ₽")])]), _vm._v(" "), _c('h3', {
      staticClass: "card__title",
      domProps: {
        "innerHTML": _vm._s(_setup.getLotValues(lot))
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "p4 card__location"
    }, [_vm._v(_vm._s(_setup.getLotInfo(lot)))])])])]), _vm._v(" "), _c('SInput', {
      attrs: {
        "error-show": lot.isShowError && ((_lot$discount = lot.discount) === null || _lot$discount === void 0 ? void 0 : _lot$discount.length) === 0,
        "error-message": "Введите скидку",
        "only-numbers": "",
        "split-thousands": ""
      },
      on: {
        "focus": function focus($event) {
          lot.isShowError = true;
        }
      },
      scopedSlots: _vm._u([{
        key: "after",
        fn: function fn() {
          return [_vm._v("₽")];
        },
        proxy: true
      }], null, true),
      model: {
        value: lot.discount,
        callback: function callback($$v) {
          _vm.$set(lot, "discount", $$v);
        },
        expression: "lot.discount"
      }
    }), _vm._v(" "), _c('SInput', {
      attrs: {
        "mask": "##.##.####",
        "only-numbers": "",
        "error-show": _setup.checkDiscountDate(lot.discountUntil),
        "error-message": "Некорректная дата"
      },
      on: {
        "focus": function focus($event) {
          lot.isShowError = true;
        }
      },
      model: {
        value: lot.discountUntil,
        callback: function callback($$v) {
          _vm.$set(lot, "discountUntil", $$v);
        },
        expression: "lot.discountUntil"
      }
    })], 1);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };