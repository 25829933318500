import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "the-menu",
    class: {
      'the-menu--closed': !_vm.sidebarStore.isSidebar
    }
  }, [_c('div', {
    staticClass: "the-menu__inner"
  }, [_c('div', {
    staticClass: "the-menu__header"
  }, [_c('div', {
    staticClass: "the-menu__header-inner"
  }, [_c('nuxt-link', {
    staticClass: "the-menu__logo-shell",
    attrs: {
      "to": _vm.homeLink
    }
  }, [_vm.sidebarStore.isSidebar ? _c('s-logo', {
    staticClass: "the-menu__logo",
    attrs: {
      "theme": "primary-white"
    }
  }) : _c('s-image-lazy', {
    staticClass: "the-menu__logo",
    attrs: {
      "src": require('@@/src/static/assets/logo.png'),
      "alt": "logo"
    }
  })], 1), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.sidebarStore.isSidebar && _vm.sidebarStore.isSidebarVisible,
      expression: "sidebarStore.isSidebar && sidebarStore.isSidebarVisible"
    }],
    staticClass: "the-menu__project-picker-shell"
  }, [_c('div', {
    staticClass: "the-menu__divider"
  }), _vm._v(" "), _vm.activeProject ? _c('s-select', {
    staticClass: "the-menu__project-picker",
    attrs: {
      "display": "string",
      "size": _vm.selectSize,
      "theme": "white-secondary"
    },
    model: {
      value: _vm.activeProject,
      callback: function callback($$v) {
        _vm.activeProject = $$v;
      },
      expression: "activeProject"
    }
  }, _vm._l(_vm.projectsStore.projectsList, function (_ref) {
    var id = _ref.id,
      name = _ref.name,
      slug = _ref.slug;
    return _c('s-option', {
      key: id,
      attrs: {
        "value": slug,
        "label": name
      }
    }, [_vm._v("\n              " + _vm._s(name) + "\n            ")]);
  }), 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('button', {
    staticClass: "the-menu__burger",
    on: {
      "click": _vm.sidebarStore.toggleSidebar
    }
  }, [_c('span', {
    staticClass: "the-menu__burger-stripe"
  })])]), _vm._v(" "), _c('nav', {
    staticClass: "the-menu__nav"
  }, _vm._l(_vm.sidebarStore.getNavItems, function (tab, index) {
    return _c('s-tooltip', {
      key: index,
      staticClass: "the-menu__nav-item",
      attrs: {
        "disabled": (tab === null || tab === void 0 ? void 0 : tab.disabled) || false,
        "theme": tab !== null && tab !== void 0 && tab.active ? 'secondary' : 'white',
        "auto-width": "",
        "position": _vm.sidebarStore.isSidebar ? 'top' : 'right',
        "timeout-to-hide": 0,
        "rounding": "m",
        "padding": "1.2rem",
        "unit": "rem"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn() {
          return [_c('s-chip', {
            attrs: {
              "disabled": (tab === null || tab === void 0 ? void 0 : tab.disabled) || false,
              "active": (tab === null || tab === void 0 ? void 0 : tab.active) || false,
              "circle": "",
              "value": "value",
              "theme": "gradient-common",
              "n-link": "",
              "href": _vm.getTabHref(tab)
            }
          }, [_c('s-icon', {
            attrs: {
              "icon": _vm.getTabIcon(tab)
            }
          })], 1)];
        },
        proxy: true
      }, {
        key: "content",
        fn: function fn() {
          return [_vm._v("\n          " + _vm._s(tab.block) + "\n        ")];
        },
        proxy: true
      }], null, true)
    });
  }), 1), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.sidebarStore.isSidebarClosing,
      expression: "!sidebarStore.isSidebarClosing"
    }],
    staticClass: "the-menu__main-content"
  }, [!_vm.userRightsStore.isNoMeetingsAccess && _vm.sidebarStore.isSidebar && _vm.sidebarStore.isSidebarVisible && !_vm.userRightsStore.isManagerCC ? _c('div', {
    staticClass: "the-menu__meeting"
  }, [_c('p', {
    staticClass: "the-menu__meeting-text"
  }, [_vm._v(_vm._s(_vm.activeMeetingStatusText))]), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isPageWithPortraits ? _c('PortraitButton', {
    staticClass: "the-menu__portrait-button"
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _c('portal-target', {
    staticClass: "the-menu__teleported-top",
    attrs: {
      "name": "asideTop"
    }
  }), _vm._v(" "), _c('portal-target', {
    staticClass: "the-menu__teleported-content",
    attrs: {
      "name": "asideContent"
    }
  }), _vm._v(" "), _c('portal-target', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.sidebarStore.isSidebar && _vm.sidebarStore.isSidebarVisible,
      expression: "sidebarStore.isSidebar && sidebarStore.isSidebarVisible"
    }],
    staticClass: "the-menu__teleported-bottom",
    attrs: {
      "name": "asideBottom"
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };