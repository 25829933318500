export default {
  name: 'FilterContainer',
  props: {
    label: {
      type: String,
      default: ''
    },
    postfix: {
      type: String,
      default: ''
    }
  }
};